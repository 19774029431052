.background-navy {
  background-color: rgb(33, 32, 49);
  height: '100vh';
}

.background-purple {
  background-color: rgb(92, 58, 131);
  min-height: '100vh';
}

.FancyBorder {
    padding: 10px;
    border: 10px solid;
  }

input[type="submit"].button:hover {
    text-decoration: underline;
    padding-right: 25px;
    padding-left: 25px;
}

.comment-section {
    width: 66%;
    background-color: #818181;
    border: 1px solid rgb(0, 0, 0);
    text-align: center;
    text-decoration: none;
    font-family:Arial, Helvetica, sans-serif;
    font: Arial, Helvetica, sans-serif;
    color: rgb(0, 0, 0); 
    cursor: pointer;
    font-size: 25px;
    margin-bottom: 20px;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
}

.comment-section:hover { 
  box-shadow: 0px 20px 20px 0px rgba(56, 180, 25, 0.411), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
  transition: .5s ease;
}

.button-greyed-out{
  background-color: #8181814f;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(78, 119, 167);
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  font-family:Arial, Helvetica, sans-serif;
  font: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0); 
  cursor:not-allowed;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
}

.button-grey{
    background-color: #818181;
    border: 1px solid rgb(0, 0, 0);
    color: rgb(78, 119, 167);
    padding: 1px 10px;
    text-align: center;
    text-decoration: none;
    font-family:Arial, Helvetica, sans-serif;
    font: Arial, Helvetica, sans-serif;
    color: rgb(0, 0, 0); 
    cursor: pointer;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
}

.button-grey-lost{
  background-color: #818181;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(78, 119, 167);
  padding: 1px 10px;
  margin: 0px 10px;
  text-align: center;
  text-decoration: none;
  font-family:Arial, Helvetica, sans-serif;
  font: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0); 
  cursor: pointer;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
  margin-bottom: 25px;
}

.button-grey-lost:hover { 
  box-shadow: 0px 20px 20px 0px rgba(56, 180, 25, 0.411), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
  transition: .5s ease;
}

.button-grey-comment{
  background-color: #818181;
  border: 1px solid rgb(0, 0, 0);
  padding: 5px 10px;
  margin-bottom: 10px;
  text-align: center;
  text-decoration: none;
  font-family:Arial, Helvetica, sans-serif;
  font: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0); 
  cursor: pointer;
  font-size: 10px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
}

.button-grey-comment:hover { 
  box-shadow: 0px 20px 20px 0px rgba(56, 180, 25, 0.411), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
  transition: .5s ease;
}

.input-grey-set{
  background-color: #818181;
  border: 1px solid rgb(0, 0, 0);
  padding: 5px 10px;
  margin-bottom: 10px;
  text-align: center;
  text-decoration: none;
  font-family:Arial, Helvetica, sans-serif;
  font: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0); 
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
}

.input-grey-set:hover { 
  box-shadow: 0px 20px 20px 0px rgba(56, 180, 25, 0.411), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
  transition: .5s ease;
}

.button-grey-shake{
  background-color: #818181;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(78, 119, 167);
  padding: 12px 10px;
  text-align: center;
  margin-top: 30px;
  text-decoration: none;
  font-family:Arial, Helvetica, sans-serif;
  font: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0); 
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
}

.button-grey-shake:hover { 
  box-shadow: 0px 20px 20px 0px rgba(56, 180, 25, 0.411), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
  transition: .5s ease;
}


.button-grey:hover { 
  box-shadow: 0px 20px 20px 0px rgba(56, 180, 25, 0.411), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
  transition: .5s ease;
}

.button-grey-nav {
  background-color: #818181;
    border: 1px solid rgb(0, 0, 0);
    color: rgb(78, 119, 167);
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    font-family:Arial, Helvetica, sans-serif;
    font: Arial, Helvetica, sans-serif;
    color: rgb(0, 0, 0); 
    cursor: pointer;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
    transition: .5s ease;
}

.button-grey-nav:hover {
  transition: .5s ease;
    box-shadow: 0px 20px 20px 0px rgba(56, 180, 25, 0.411), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);

}

.button-grey-notifications {
  background-color: #81818100;
    border: 1px solid rgb(0, 0, 0);
    color: rgb(78, 119, 167);
    padding: 0px 0px;
    text-align: center;
    text-decoration: none;
    font-family:Arial, Helvetica, sans-serif;
    font: Arial, Helvetica, sans-serif;
    font-size: 24px;
    color: rgb(0, 0, 0); 
    cursor: pointer;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
    transition: .5s ease;
}

.button-grey-notifications:hover {
  transition: .5s ease;
    box-shadow: 0px 20px 20px 0px rgba(56, 180, 25, 0.411), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
}



.button-grey-padding{
  background-color: #818181;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(78, 119, 167);
  padding: 20px 10px;
  text-align: center;
  text-decoration: none;
  font-family:Arial, Helvetica, sans-serif;
  font: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0); 
  cursor: pointer;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2), 0px 20px 10px 0px rgba(0, 0, 0, 0.637);
}

  .button {
    background-color: #1a1336;
    border: 1px solid rgb(133, 160, 185);
    color: rgb(78, 119, 167);
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 12px;
    font-family:Arial, Helvetica, sans-serif;
    font: Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: all 0.5s;
    box-shadow: 0 5px 5px 0 rgba(156, 205, 228, 0.2), 0 6px 20px 0 rgba(48, 42, 65, 0.637);
  }
  
  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .button:hover span {
    padding-right: 25px;
  }

  .button:hover span:after {
    opacity: 1;
    right: 0;
  }

  .FancyBorder-blue {
    border-color: rgb(117, 83, 156);
  }

  .FancyBorder-comment {
    border-color: rgb(117, 83, 156);
    background-color: #3b2334;
  }

  .FancyBorder-creamsicle {
    border-color: rgb(248, 188, 139);
  }

  .FancyBorder-bright-pink {
    color: rgb(255, 125, 255);
  }
  
  .FancyBorder-lime {
    color: rgb(160, 247, 167);
  }

  .twenty-font {
    font-size: 20px;
  }

  .comment-name-left {
    color: rgb(229, 149, 149);
    font-size: 24px;
    margin-left: 20%;
    margin-bottom: 10px;
    text-align: left;
  }

  .comment-name-right {
    color: rgb(184, 78, 78);
    font-size: 24px;
    margin-right: 20%;
    margin-bottom: 10px;
    text-align: right;
  }

  .comment-body-left {
    text-align: center;
    font-size: 16px;
    margin-right: 10%;
    margin-left: 10%;
    color: rgb(165, 220, 241);
  }
  .comment-body-right {
    text-align: center;
    font-size: 16px;
    margin-right: 10%;
    margin-left: 10%;
    color: rgb(83, 158, 187);
  }

  .line-center {
    margin-top: 10px;
    margin-bottom: 30px;
    word-wrap: break-word;
    min-width: 0;
    max-width:100%;
  }

  .Dialog-title {
    margin: 0;
    font-family: sans-serif;
  }
  
  .Dialog-message {
    font-size: larger;
  }
  
  html, body, #root {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: rgb(92, 58, 131);
    font-family:Arial, Helvetica, sans-serif;
    font:Arial, Helvetica, sans-serif;
    font-size:14px;
    color: rgb(165, 220, 241);
  }
  
  .SplitPane {
    width: 100%;
    height: 100%;
  }
  
  .SplitPane-left {
    float: left;
    width: 30%;
    height: 100%;
  }
  
  .SplitPane-right {
    float: left;
    width: 70%;
    height: 100%;
  }
  
  .Contacts {
    width: 100%;
    height: 100%; 
    background: lightblue;
  }
  
  .Chat {
    width: 100%;
    height: 100%; 
    background: pink;
  }
  
  input {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  
  .container {
    height: 20px;
    position: relative;
  }

  .line-center-old {
    display: flex;
    word-wrap: break-word;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    flex-shrink: 1;
    flex-direction: 'column';
    flex-wrap: wrap;
  }

  .line-center-temp {
    overflow-wrap: break-word;
  }

  .line-center-flex {
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .line-center {
    word-wrap: break-word;
    min-width: 0;
    max-width:100%;
  }

  .line-center-border {
    word-wrap: break-word;
    min-width: 0;
    max-width:100%;
  }

  .margin-catch {
    margin: 100px 100px;
  }

  .player-shake-border {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0px;
    border: 4px solid rgb(133, 160, 185);
  }

  .size-35 {
    font-size: 35px;
  }
  .size-24 {
    font-size: 24px;
  }

  .orange {
    color: rgb(230, 121, 121);
  }

  .bright-pink {
    color: rgb(255, 125, 255);
  }

  .bright-pink-large {
    color: rgb(255, 125, 255);
    font-size: 35px;
  }

  .green-large {
    color: rgb(63, 172, 121);
    font-size: 35px;
  }

  .light-blue {
    color: rgb(165, 220, 241);
  }

  .lime {
    color: rgb(160, 247, 167);
  }

  .lime-large {
    color: rgb(160, 247, 167);
    font-size: 35px;
  }

  .creamsicle {
    color: rgb(248, 188, 139);
  }

  .creamsicle-large {
    color: rgb(248, 188, 139);
    font-size: 35px;
  }


  .home-center{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0px;
    max-width: 100%;
    
    height: 50vh;
    width: 100vh;
    text-align: center;
  }

  .default-center{
    position: absolute;
    top: 33%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0px;
    max-width: 100%;
    
    height: 50vh;
    width: 100vh;
    text-align: center;
  }
  
  
  .home-center-test{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 10px solid;
  }

  .top-third-center{
    margin: 20;
    position: absolute;
    top: 33%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .horizontal-center{
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .center-top {
    margin: 0;
    position: absolute;
    top: 15%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 33%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .center-left{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 33%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .center-right{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 66%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .block-right {
    display: block;
    margin-right: auto;
  }

  .block-center {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  
  .float-container {
    padding: 25px;
  }

  .float-child {
    width: 50%;
    float: left;
    padding: 20px;
    border: 2px solid red;
  }
  
  .ledger-button-center {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }   

  .bgContainer{
    background-image: url(./images/logo192.png);
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  .center-text {
    padding: 10px;
    text-align: center;
  }

  .Title {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size:18px;
    margin-bottom: 2em;
    background-color: rgb(171, 203, 224);
    color: rgb(27, 28, 58);
  }
  
  .sticky-left {
    position: fixed;
    top: 1em;
    left:1em;
    z-index: 100;
    transition: .5s ease;
  }

  .sticky-right {
    position: fixed;
    top: 2em;
    right: 2em;
    z-index: 100;
    transition: .5s ease;
  }

  .opaque-hover:hover {
    transition: .5s ease;
  }

  .sticky-left:hover {
    transition: .5s ease;
    top:.5em;
  }

  .tooltip {
    font: Arial, Helvetica, sans-serif !important;
    background-color:rgb(71, 39, 109) !important;
    color: rgb(230, 121, 121) !important; 
    font-size: 20px !important;
    pointer-events: auto !important;
    opacity: 1 !important
  }
  
  .tooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  .tooltip-pad {
    padding: 0px 0px 0px 10px;
  }
 
  .draw-panel{
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0px;
    max-width: 100%;
    
    height: 50vh;
    width: 100vh;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #4CAF50;
    cursor: pointer;
  }